"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * http://openapi.baidu.com/oauth/2.0/token?grant_type=client_credentials&client_id=IUrRQhrSNZMOGuTtcd1L1mgb&client_secret=FjESWGXnxGllm8kzCPSe4NC7o1fQ910B
 * 资料详情
 * @param {int} id
 */
function getToken(id) {
  /* return new Promise(resolve => {
       resolve({access_token:'24.1db09ea2a9fbdcf553a116d419e35ed5.2592000.1585370044.282335-18290114'});
  },reject=>{
   }) */
  return (0, _request.default)({
    url: '/oauth/2.0/token?grant_type=client_credentials&client_id=IUrRQhrSNZMOGuTtcd1L1mgb&client_secret=FjESWGXnxGllm8kzCPSe4NC7o1fQ910B',
    method: 'GET'
  });
}