"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAdList = getAdList;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _request = _interopRequireDefault(require("@/utils/request"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 *@param {obj} searchObj
 * @param {int} page
 * @param {int} page_size
 */
function getAdList(searchObj) {
  var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var page_size = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;
  return (0, _request.default)({
    url: '/ad/index_banner',
    method: "get",
    params: _objectSpread({}, searchObj, {
      page: page,
      page_size: page_size
    }),
    showLoading: false
  });
}