"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getArticleList = getArticleList;
exports.getArticleDetail = getArticleDetail;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _request = _interopRequireDefault(require("@/utils/request"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 *@param {obj} searchObj
 * @param {int} page
 * @param {int} page_size
 */
function getArticleList(searchObj) {
  var _searchObj$page = searchObj.page,
      page = _searchObj$page === void 0 ? 1 : _searchObj$page,
      _searchObj$page_size = searchObj.page_size,
      page_size = _searchObj$page_size === void 0 ? 10 : _searchObj$page_size;
  return (0, _request.default)({
    url: '/article',
    method: 'get',
    params: _objectSpread({}, searchObj, {
      page: page,
      page_size: page_size
    })
  });
}
/**
 * 资料详情
 * @param {int} id
 */


function getArticleDetail(id) {
  return (0, _request.default)({
    url: '/article/' + id,
    method: 'get'
  });
}